import { v4 as uuidv4 } from 'uuid';

// HEAD DATA
export const headData = {
  title: 'Vinicius Alvim', // e.g: 'Name | Developer'
  lang: 'en', // e.g: en, es, fr, jp
  description: 'Vinicius Alvim - Fullstack Developer', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: '',
  name: 'Vinicius Alvim',
  subtitle: "I'm a Software Developer.",
  cta: '',
};

// ABOUT DATA
export const aboutData = {
  img: 'profile.jpg',
  paragraphOne:
    "I'm a curious guy interested in technological things and philosophical questions. I work as a Fullstack Developer, dealing with the JS ecosystem (mostly React and Node.js).",
  paragraphTwo: 'You can get in touch with me by LinkedIn, GitHub or email.',
  paragraphThree: 'See you! 🙂🚀',
  resume:
    'https://www.canva.com/design/DAEXheZdOoI/5a-mYkKK7N7si3dZuns0zA/view?utm_content=DAEXheZdOoI', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: uuidv4(),
    img: 'astrobay.png',
    title: 'Astrobay',
    info:
      'Techs: TypeScript, React (with hooks and ContextAPI), Next.js, Styled-components and MongoDB.',
    info2: '',
    url: 'https://astrobay.vercel.app/',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: uuidv4(),
    img: 'crwn-clothing.png',
    title: 'CRWN Clothing',
    info: 'Techs: React, Redux, Styled-components and Firebase.',
    info2: '',
    url: 'https://crwn-clothing.vercel.app/',
    repo: 'https://github.com/vini0191/crwn-clothing', // if no repo, the button will not show up
  },
  {
    id: uuidv4(),
    img: 'forkify.png',
    title: 'forkify',
    info: 'Techs: Basic JS project using a simple Webpack config.',
    info2: '',
    url: 'https://forkify-amber.now.sh/',
    repo: 'https://github.com/vini0191/forkify', // if no repo, the button will not show up
  },
  // {
  //   id: uuidv4(),
  //   img: 'project.jpg',
  //   title: '',
  //   info: '',
  //   info2: '',
  //   url: '',
  //   repo: 'https://github.com/cobidev/react-simplefolio', // if no repo, the button will not show up
  // },
];

// CONTACT DATA
export const contactData = {
  cta: '',
  btn: '',
  email: 'vini0191@gmail.com',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: uuidv4(),
      name: 'github',
      url: 'https://github.com/vini0191',
    },
    // {
    //   id: uuidv4(),
    //   name: 'codepen',
    //   url: '',
    // },
    {
      id: uuidv4(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/vinicius--alvim/',
    },
    {
      id: uuidv4(),
      name: 'twitter',
      url: 'https://twitter.com/vini0191',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
